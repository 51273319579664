@import 'common/styling/_variables.scss';

.pre-contractual-info {
	.pre-contractual-header,
	.pre-contractual-link,
	.psd2-button-section {
		text-align: center;
	}

	a {
		@include linkGray11(true);
		text-align: center !important;
	}
	.checkbox-container {
		@include checkbox;
	}

	.psd2-button-section {
		margin-top: 15px;
	}
}