@import 'common/styling/_variables.scss';

.cookie-consent {
	position: fixed;
	left: 0;
	margin: 1rem 2vw;
	width: 100%;
	max-width: 96vw;
	border-radius: $border-radius-small;
	background-color: $primary-warmgray-2 !important;
	z-index: 9090;
	text-align: center;
	@include shadow($shadow-4);
	@include transitionBase;

	@media screen and (min-width: 374.98px) {
		margin: 2rem;
		max-width: 300px;
	}

	button {
		@include btnBase;
		@include btnPrimaryBronze;
		@include ripple(#eea87e, $primary-bronze-1, 399ms);
		margin-bottom: 1.5rem !important;
	}

	a {
		@include linkBronze(true);
		display: block;
		width: 100%;
		text-align: center;
		margin-top: 0.5rem;
	}

	.cookie-text {
		padding: $general-padding-1 15px;
		color: $primary-warmgray-11;

		.svg-inline--fa {
			font-size: 50px;
			margin: 1rem auto 0;
			display: block;
		}
	}
}
