@import 'common/styling/_variables.scss';

.payment-protection-insurance {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.ppi-description {
		margin-bottom: 25px;
		margin-top: 3rem;
	}

	.ppi-terms {
		font-size: 15px;
		padding-left: 25px;

		div {
			padding-bottom: 10px;
		}
	}

	.ppi-link-section {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
		margin-bottom: 10px;

		a {
			padding-top: 15px;
		}
	}

	.ppi-information-section {
		h6 b {
			font-size: 19px;
		}
		div {
			margin-bottom: 15px;
		}
	}

	.checkbox-container {
		@include checkbox;
	}

	.ppi-next-button {
		margin-top: 15px;
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;

		@media screen and (max-width: $screen-md) {
			margin-bottom: 30px;
		}
		button {
			max-width: 335px;
			min-width: 300px;
		}
	}

	.error-box-container {
		margin-top: 15px;
	}

	.box-info.success.has-ppi {
		margin: 0;
		font-size: 15px;
		padding: 25px 37px 25px 80px;
	}

	/* Adding a bit of blurr for expander... might delete later. Not really happy with it.*/
	.read-more-expander {
		.expander-item {
			margin-top: 0px;
			background-image: linear-gradient(
				rgba(239, 238, 227, 0.5),
				#f6f5ee
			);
			padding-top: 10px;
		}
	}
}
