@import 'common/styling/_variables.scss';

.update-bank-info {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 15px;

	.col-spinner {
		margin-top: 30px;
	}

	@media screen and (min-width: $screen-md) {
		padding-bottom: 0;
	}

	.update-bank-info-header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 1rem 0;

		h3 {
			text-align: center;
			font-weight: 600;
			margin: auto;
			max-width: 670px;
		}
		hr {
			max-width: 670px;
		}
	}

	.error-box-container {
		margin-top: 15px;
	}

	.update-bank-info-select-container {
		margin: 2rem 0;

		.radio-button-type {
			display: flex;
			width: fit-content;
			flex-direction: row;
			margin-top: 1rem;

			&:hover {
				cursor: pointer;
			}

			input[type='radio'] {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				margin: 0.3rem 1rem 0 0.75rem;
				width: 20px;
				height: 20px;
				min-width: 20px;
				max-width: 20px;
				background-color: $white;
				color: $primary-bronze-1;
				border: 1px solid $primary-warmgray-11;
				border-radius: 50%;
				display: grid;
				place-content: center;
			}

			input[type='radio']::before {
				content: '';
				width: 1.2rem;
				height: 1.2rem;
				border-radius: 50%;
				transform: scale(0);
				transition: 120ms transform ease-in-out;
				box-shadow: inset 1em 1em $primary-bronze-1;
			}

			input[type='radio']:checked::before {
				transform: scale(1);
			}

			label {
				span {
					display: flex;
				}
			}
		}
	}

	.update-bank-info-add-accountnr-container {
		margin: 1rem 0 2rem 0;

		.update-bank-info-add-accountnr-description {
			font-size: 1.6rem;
			max-width: 670px;
			margin-left: auto;
			margin-right: auto;
			padding-left: 0.5rem;
		}
	}

	.update-bank-info-next-button {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 3rem;
		margin-bottom: 3rem;

		@media screen and (min-width: $screen-lg) {
			margin-bottom: 0;
		}

		button {
			width: 100%;
			max-width: 335px;
			min-width: 300px;
			min-height: 46px;
		}
	}

	&.norway {
		.form-control {
			padding: 1.1rem 2rem;
			margin: 32px auto 0 auto;
			max-width: 400px;
			font-size: 1.7rem;
			height: 46px;
			color: #575651;
			border: 1px solid #575651;
			border-radius: $border-radius-small;
			&:focus {
				color: #575651;
				border: 1px solid #de996f !important;
				box-shadow: 0 0 0 0.08rem #de996f;
				outline: 0;
			}
		}

		.update-bank-info-next-button {
			.spinner-container {
				display: flex;
				justify-content: center;
			}

			@media screen and (min-width: $screen-lg) {
				margin-bottom: 4rem;
			}
		}

		.update-bank-info-section {
			input {
				padding-top: 1.1rem;
				max-width: 670px;
				margin-left: auto;
				margin-right: auto;
			}

			.error-box-container {
				margin-top: 0;

				.box-info.fail {
					margin-bottom: 0;
				}
			}

			.col-spinner {
				margin-bottom: 30px;
			}
		}
	}

	&.denmark {
		.update-bank-info-section {
			.form-box {
				padding-left: 5px;
				padding-right: 5px;
			}

			.update-bank-info-description {
				h6,
				p {
					padding-bottom: 1rem;
				}

				h6 {
					font-size: 1.8rem;
					font-weight: 600;
					color: $black;
				}
			}
		}
	}
}
